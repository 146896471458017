export default {
  route: {
    dashboard: 'Dashboard',

    goods: 'Goods Manage',
    goodsList: 'Goods List',
    goodsSetting: 'Goods Setting',
    categoryList: 'Category List',
    categoryParams: 'Category Params',
    brandList: 'Brand List',
    specList: 'Spec List',
    goodsAudit: 'Goods Audit',
    intelOperation: 'Intel Operation',
    goodsAuditDetail: 'Goods Audit Detail',
    goodsPriceChange: 'goods Price Change',

    integralArea: 'integral area',

    account: 'Account',
    accountList: 'Account List',
    accountDetail: 'Account Detail',

    supplier: 'Supplier',
    supplierList: 'Supplier List',
    supplierEdit: 'Supplier Edit',
    goodsPublish: 'goodsPublish',

    memberStaff: 'member-staff',
    memberStaffList: 'member-staffList',
	unionStaffList: 'union-StaffList',
    seeImport: 'see-import',

    order: 'Manage Order',
    orderList: 'Order List',
	unorderList: 'Union Order List',
    refundList: 'Refund List',
    collectionList: 'Collection List',
    receiptHistory: 'Receipt History',
    receiptDetail: 'Receipt Detail',
    orderDetail: 'Order Detail',
    refundDetail: 'Refund Detail',

    member: 'Member Manage',
    memberManage: 'Member Manage',
    memberList: 'Member List',
    memberRecycle: 'Member Recycle',
    goodsComment: 'Goods Comment',
    goodsCommentList: 'Goods Comment List',
    goodsAskList: 'Goods Ask List',
    memberNotification: 'Member Notification',
    notificationHistory: 'Site Notification',
    memberEdit: 'editMember',

    shop: 'Manage Shop',
    shopManage: 'Shop Manage',
    shopList: 'Shop List',
    shopAudit: 'Shop Audit',
    shopThemeManage: 'Shop Theme',
    themeList: 'Theme List',
    themeListWap: 'Wap Theme List',
    shopEdit: 'Shop Edit',
    settlementManage: 'Settlement Manage',
    settlement: 'settlement',
    settlementList: 'Settlement List',
    settlementDetail: 'Settlement Detail',

    promotions: 'promotions',
    groupBuyManage: 'GroupBuy Manage',
    groupBuyList: 'GroupBuy List',
    groupBuyCategory: 'GroupBuy Category',
    pointsMallManage: 'Points Mall Manage',
    pointsClassify: 'Points Classify',
    pointsGoods: 'Points Goods',
    seckillManage: 'Seckill Manage',
    seckillList: 'Seckil lList',
    groupBuyGoods: 'GroupBuy Goods',
    groupBuyGoodsInfo: 'GroupBuy Goods Info',
    seckillAuditGoodsList: 'Seckill Audit Goods List',
    seckillPassGoodsList: 'Seckill Pass Goods List',

    page: 'Page Manage',
    pcDecoration: 'PC Decoration',
    themeManage: 'PC Theme Manage',
    pcThemeManage: 'Theme Manage',
    pcFloorManage: 'Floor Manage',
    pcFocusManage: 'Focus Manage',
    mobileDecoration: 'Mobile Decoration',
    mobileThemeManage: 'Theme Manage',
    mobileFloorManage: 'Floor Manage',
    mobileFocusManage: 'Focus Manage',
    pageSetting: 'Page Setting',
    pcSiteMenu: 'PC Site Menu',
    mobileSiteMenu: 'Mobile Site Menu',
    hotKeyword: 'Hot Keyword',

    statistics: 'Statistics Manage',
    sale: 'Sale',
    census: 'Census',
    flowData: 'Flow Data',
    revenueData: 'Revenue Data',
    memberAnalysis: 'Member Analysis',
    orderAmount: 'Order Amount',
    addedMember: 'Added Member',
    goodsStatistics: 'Goods Statistics',
    priceSales: 'Price Sales',
    hotGoods: 'Hot Goods',
    goodsSalesDetails: 'Goods Sales Details',
    goodsCollect: 'Goods Collect',
    industryAnalysis: 'Industry Analysis',
    industryScale: 'Industry Scale',
    generalityOverview: 'Generality Overview',
    trafficAnalysis: 'Traffic Analysis',
    trafficAnalysisShop: 'Shop Traffic Analysis',
    trafficAnalysisGoods: 'Goods Traffic Analysis',
    otherStatistics: 'Other Statistics',
    orderStatistics: 'Order Statistics',
    salesRevenueStatistics: 'Sales Revenue Statistics',
    regionalAnalysis: 'Regional Analysis',
    customerPriceDistribution: 'Customer Price Distribution',
    refundStatistics: 'Traffic AnalysisGoods',

    setting: 'Setting Mange',
    shopSettings: 'Shop Setting',
    systemSettings: 'System Settings',
    smtpSettings: 'SMTP Settings',
    SMSGatewaySettings: 'SMS Gateway Setting',
    expressPlatformSettings: 'Express Platform Settings',
    electronicReceipt: 'Electronic Receipt',
    storageSolution: 'Storage Solution',
    staticPage: 'Static Page',
    goodsIndex: 'Goods Index',
    shopGoodsIndex: 'Shop Goods Index',
    trustLogin: 'Trust Login',

    messageSettings: 'Message Settings',
    shopMessage: 'Shop Message',
    memberMessage: 'Member Message',

    pushSettings: 'Push Settings',
    goodsPush: 'Goods Push',
    appPush: 'APP Push',

    paymentAndDelivery: 'Payment And Delivery',
    payment: 'payment',
    express: 'Express',
    regionalManagement: 'Regional Management',

    authSettings: 'Auth Settings',
    administratorManage: 'Administrator Manage',
    roleManage: 'Role Manage',
    rolePermission: 'Role Permission',

    development: 'Development',
    toolManage: 'Tool',
    URLMapping: 'URL Mapping',
    menuManage: 'Menu Manage',
    articleManage: 'Article Manage',
    articleCategory: 'Article Category',
    articleList: 'Article List',
    addArticle: 'Add Article',

    distribution: 'Distribution',
    extractTpl: 'Extract Template',
    perAccomplishmentTpl: 'Person Accomplishment Template',
    upgradeLogs: 'Upgrade Logs',
    distributor: 'Distributor Manage',
    distributorList: 'Distributor List',
    distributorStatistics: 'distributor Statistics',
    achievement: 'Achievement',
    achievementList: 'Achievement List',
    billList: 'bill List',
    billDetails: 'Bill Details',
    putforwardManage: 'Put Forward Manage',
    putforwardSettings: 'Put Forward Settings',
    putforwardApply: 'Put Forward Apply'
  },
  login: {
    logIn: 'Log in',
    username: 'Administrator name',
    password: 'Administrator password',
    validcode: 'Image verification code',
    val_username: 'Please enter the administrator name!',
    val_password: 'Please enter the administrator password!',
    val_validcode: 'Please enter the image verification code!',
    val_form: 'Fill in the form is incorrect, please check!'
  },
  tagsView: {
    close: 'Close',
    closeOthers: 'Close Others',
    closeAll: 'Close All'
  }
}
