/**
 * Created by Andste on 2018/5/9.
 */

import Vue from 'vue'
import { Foundation } from '@/../ui-utils'
import { api, domain } from '@/../ui-domain'
import * as XLSX from 'xlsx'

export default {
	data() {
    const hostIncludeSome = (function(f){
      return function(...arr){
        return arr.some(i => f(i))
      }
    })(location.host.includes.bind(location.host))
		return {
      MixinEnv: hostIncludeSome('test','localhost','172.18') ? (hostIncludeSome('two','172.18') ? 'dev2' : 'dev1') : 'pro' ,
			MixinPageSizes: [10, 20, 50, 100],
			MixinTableLayout: 'total, sizes, prev, pager, next, jumper',
			// 图片上传API
			MixinUploadApi: api.base + '/uploaders',
			// 地区API
			MixinRegionApi: api.base + '/regions/@id/children',
			MixinDefaultTimes: ['00:00:00', '23:59:59'],
			// 买家端域名
			MixinBuyerDomain: domain.buyer_pc,
			// 日期选择器快捷选项
			MixinPickerShortcuts: [{
				text: '最近一周',
				onClick(picker) {
					const end = new Date()
					const start = new Date()
					start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
					picker.$emit('pick', [start, end])
				}
			}, {
				text: '最近一个月',
				onClick(picker) {
					const end = new Date()
					const start = new Date()
					start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
					picker.$emit('pick', [start, end])
				}
			}, {
				text: '最近三个月',
				onClick(picker) {
					const end = new Date()
					const start = new Date()
					start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
					picker.$emit('pick', [start, end])
				}
			}]
		}
	},
	computed: {
		/**
		 * 缓存页面数组
		 * @returns {default.computed.cachedViews|(function())|Array|*|getters.cachedViews}
		 */
		cachedViews() {
			return this.$store.state.tagsView.cachedViews
		},
		/**
		 * 返回默认时间 + 5分钟
		 * 用于日期时间选择器的默认时间
		 * @returns {string}
		 * @constructor
		 */
		MixinDefaultTime() {
			const today = new Date()
			let hours = today.getHours()
			let minutes = today.getMinutes() + 5
			let seconds = today.getSeconds()
			if (hours < 10) hours = '0' + hours
			if (minutes < 10) minutes = '0' + minutes
			if (seconds < 10) seconds = '0' + seconds
			return hours + ':' + minutes + ':' + seconds
		}
	},
	mounted(){
		// this.$nextTick(_=>{
		// 	$('.el-dialog__body').each((_i,_e)=>{
		// 		$(_e).find('.el-button').each((i,e)=>{
		// 			if(!$(e).hasClass('moved')){
		// 				if(e.innerText.trim() === '确认'){
		// 					$(e).addClass('moved')
		// 					$(e).attr("style",`
		// 					position: relative;
		// 					right: -83%;
		// 					`)
		// 					$(_e).append(e)
		// 				}
		// 				if(e.innerText.trim() === '取消'){
		// 					$(e).addClass('moved')
		// 					$(e).attr("style",`
		// 					position: relative;
		// 					right: -80%;
		// 					`)
		// 					$(_e).append(e)
		// 				}
		// 			}
		// 		})
		// 	})
		// })
	},
	methods: {
		/** 返回克隆后的对象 */
		MixinClone(obj) {
			return JSON.parse(JSON.stringify(obj))
		},
		/** 用于修改单条表格数据后，set到tableData中 */
		MixinSetTableData(tableData, idName, id, response) {
			const { data } = tableData
			const index = data.findIndex(item => item[idName] === id)
			Vue.set(data, index, response)
		},
		/** 用于判断表单是否为空 */
		MixinRequired(message, trigger) {
			// return { required: true, pattern: /^\S.*$/gi, message: message + '[不能空格开始]', trigger: trigger || 'blur' }
			return { required: true, pattern: /^\S.*$/gi, message: message ? message : message + '[不能空格开始]', trigger: trigger || 'blur' }
		},
		/** 格式化金钱 */
		MixinFormatPrice(row, column, cellValue, index) {
			if (typeof cellValue !== 'number') return ''
			return '￥' + Foundation.formatPrice(cellValue)
		},
		/** 格式化时间戳 */
		MixinUnixToDate(row, column, cellValue, index) {
			return Foundation.unixToDate(cellValue)
		},
		/**
		 * 导出Excel
		 * @param json   要导出的json数据
		 * @param name   要导出的文件名
		 * @param type   要导出的数据类型
		 * @constructor
		 */
		MixinExportJosnToExcel(json, name = 'data', type = 'application/octet-stream') {
			let wb = { SheetNames: [], Sheets: {}, Props: { }}
			if (!Array.isArray(json)) json = [json]
			json.forEach(item => {
				wb.SheetNames.push(item.sheet_name)
				wb.Sheets[item.sheet_name] = XLSX.utils.json_to_sheet(item.sheet_values, item.sheet_options)
			})
			const wopts = { bookType: 'xlsx', bookSST: false, type: 'binary' }
			let blob = new Blob([s2ab(XLSX.write(wb, wopts))], { type })
			let link = document.createElement('a')
			link.href = window.URL.createObjectURL(blob)
			link.download = `${name}.xlsx`
			link.click()
			// 释放资源
			setTimeout(() => {
				URL.revokeObjectURL(link.href)
			}, 100)

			function s2ab(s) {
				if (typeof ArrayBuffer !== 'undefined') {
					const buf = new ArrayBuffer(s.length)
					const view = new Uint8Array(buf)
					for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF
					return buf
				} else {
					const buf = new Array(s.length)
					for (let i = 0; i !== s.length; ++i) buf[i] = s.charCodeAt(i) & 0xFF
					return buf
				}
			}
		}
	}
}
