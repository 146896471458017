export default {
  route: {
    dashboard: '首页',

    goods: '商品',
    goodsList: '商品列表',
    Categories: '商品分类',
    Categoriessec: '二级分类',
    goodsSetting: '商品设置',
    categoryList: '分类列表',
    categoryParams: '分类参数',
    brandList: '品牌列表',
    specList: '规格列表',
    goodsAudit: '商品审核',
    intelOperation: '智能运营',
    company: '企业管理',
    goodsAuditDetail: '商品审核详情',
    goodsPriceChange: '商品改价记录',

    integralArea: '商品类型',

    commanagement: '企业列表',
    comlevel: '企业等级管理',
    comPublish: '企业编辑资料',
    comAudit: '企业短信签名审核',

    account: '对账',
    accountList: '对账列表',
    accountchange: '对账质保金变动记录',
    accountDetail: '对账详情',

    supplier: '供应商',
    supplierList: '供应商列表',
    retentionChange: '质保金变动记录',
    supplierEdit: '供应商编辑',
    goodsPublish: '分类添加',

    memberStaff: '员工会员',
    memberStaffList: '会员列表',
    unionStaffList: '企业会员',
    seeImport: '导入批次',

    order: '订单',
    orderList: '订单列表',
    orderLis: '订单列表',
    powerList: '维权订单',
    serveorderlist: '服务订单',
    unorderList: '企业会员订单',
    refundList: '退款单',
    collectionList: '收款单',
    receiptHistory: '开票历史',
    receiptDetail: '开票详情',
    orderDetail: '订单详情',
    powerorderDetail: '订单详情',
    refundDetail: '售后详情',

    member: '会员管理',
    memberManage: '管理会员',
    memberList: '会员列表',
    memberRecycle: '会员回收站',
    goodsComment: '商品评论',
    goodsCommentList: '商品评论列表',
    goodsAskList: '商品咨询列表',
    memberNotification: '会员通知',
    notificationHistory: '站内消息',
    memberEdit: '编辑会员',

    shop: '店铺',
    shopManage: '管理店铺',
    shopList: '店铺列表',
    shopAudit: '店铺审核',
    shopThemeManage: '店铺模板管理',
    themeList: '模板列表',
    themeListWap: 'WAP模板列表',
    shopEdit: '编辑店铺',
    settlementManage: '店铺结算管理',
    settlement: '店铺结算单',
    settlementList: '结算列表',
    settlementDetail: '结算单详情',

    promotions: '促销',
    groupBuyManage: '团购管理',
    groupBuyList: '团购列表',
    groupBuyCategory: '团购分类',
    pointsMallManage: '积分商城',
    pointsClassify: '积分分类',
    pointsGoods: '积分商品',
    seckillManage: '限时抢购管理',
    seckillList: '限时抢购',
    groupBuyGoods: '团购商品',
    groupBuyGoodsInfo: '团购商品信息',
    seckillAuditGoodsList: '审核限时抢购商品',
    seckillPassGoodsList: '已通过限时抢购商品',

    page: '集市',
    pcDecoration: 'PC装修',
    pcThemeManage: '模板管理',
    pcFloorManage: '楼层装修',
    pcFocusManage: '焦点图管理',
    mobileDecoration: '移动端装修',
    mobileThemeManage: '模板管理',
    mobileFloorManage: '楼层装修',
    mobileFocusManage: '焦点图管理',
    pageSetting: '页面设置',
    pcSiteMenu: '集市导航管理',
    mobileSiteMenu: '移动端导航菜单',
    hotKeyword: '热门关键字',

    statistics: '统计',
    sale: '销售统计',
    census: '销售统计',
    flowData: '流量数据',
    revenueData: '营收数据',
    memberAnalysis: '会员分析',
    orderAmount: '会员下单量',
    addedMember: '新增会员统计',
    goodsStatistics: '商品统计',
    priceSales: '价格销量',
    hotGoods: '热卖商品统计',
    goodsSalesDetails: '商品销售明细',
    goodsCollect: '商品收藏统计',
    industryAnalysis: '行业分析',
    industryScale: '行业规模',
    generalityOverview: '概括总览',
    trafficAnalysis: '流量分析',
    trafficAnalysisShop: '店铺流量分析',
    trafficAnalysisGoods: '商品流量分析',
    otherStatistics: '其它统计',
    orderStatistics: '订单统计',
    salesRevenueStatistics: '销售收入统计',
    regionalAnalysis: '区域分析',
    customerPriceDistribution: '客单价分布图',
    refundStatistics: '退款统计',

    setting: '设置',
    shopSettings: '网店设置',
    systemSettings: '系统设置',
    smtpSettings: 'SMTP设置',
    smsGatewaySettings: "短信网关设置",
    expressPlatformSettings: '快递平台设置',
    electronicrEceipt: "电子面单",
    storageSolution: '储存方案',
    staticPage: '静态页',
    goodsIndex: '商品索引',
    shopGoodsIndex: '店铺商品索引',
    trustLogin: '信任登录',
    imageList: "图片库管理",
    imageTypeList: "图片库分类管理",
    giftCategoryList:"礼包分类管理",
    messageSettings: '消息设置',
    shopMessage: '店铺消息',
    memberMessage: '会员消息',
    shopMessageSettings: "店铺设置",
    pushSettings: '推送设置',
    goodsPush: '商品推送',
    memberMessageSettings: "会员消息设置",
    appPush: 'APP推送',
    paymentSettings: "支付设置",


    paymentAndDelivery: '支付和配送',
    payment: '支付方式',
    transportationExp: '运费设置',
    express: '物流公司',
    regionalManagement: '区域管理',

    role: '权限',
    authSettings: '权限管理',
    administratorManage: '成员管理',
    roleManage: '角色管理',
    rolePermission: '角色权限',

    development: '开发',
    toolManage: '工具',
    URLMapping: 'URL映射',
    menuManage: '菜单管理',
    articleManage: '文章管理',
    articleCategory: '文章分类',
    articleList: '文章列表',
    addArticle: '添加文章',

    distribution: '分销',
    extractTpl: '提成模板',
    perAccomplishmentTpl: '个人提成模板',
    upgradeLogs: '升级日志',
    distributor: '分销商管理',
    distributorList: '个人分销商',
    distributorStatistics: '分销商统计',
    achievement: '业绩管理',
    achievementList: '业绩列表',
    billList: '账单列表',
    billDetails: '结算单详情',
    putforwardManage: '提现管理',
    putforwardSettings: '提现设置',
    putforwardApply: '提现申请',

        finance: '财务',
        AccountManage: '企业账户管理',
        signreviw: '短信签名审核',
        accountreviw: '账户变动审核',
        rechargeRecord: '平台充值记录',
        enterpriseManual: '企业手动充值',
        rechargeriew: '供应商资金审核',
        rechagriew: '提现审核',
        chagriew: '质保金充值审核',
        invoice: '发票管理',
        terraceInvoice: '供应商发票索取',
        enterpriseInvoice: '企业发票审核',
        paymentaccount: '预付款账户充值',

    news: '消息',
    newslist: '消息提醒',
    nnewsmange: '站内消息',
    mnewsmange: '手机短信',
    platinform: '平台信息',
    protocol: '协议及合同',
    logistics: '物流公司',
    transportg: '运费设置',
    safemanege: '安全设置',
    orderSystem: '订单设置',

    dashbo: '首页',
    login: '登录日志',
    handle: '操作日志',

    enterp: '企业',
    enterplist: '企业列表',
    enterpadd: '添加企业',
    levelmanege: '等级管理',

  },
  login: {
    logIn: '登录',
    username: '请输入管理员名称',
    password: '请输入管理员密码',
    validcode: '请输入图片验证码',
    val_username: '请输入管理员名称',
    val_password: '请输入管理员密码',
    val_validcode: '请输入图片验证码',
    val_form: '表单填写有误，请检查！'
  },
  tagsView: {
    close: '关闭',
    closeOthers: '关闭其它',
    closeAll: '关闭所有'
  }
}
