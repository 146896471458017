import Cookies from 'js-cookie'

const app = {
	state: {
		sidebar: {
			opened: true,
			// opened: !+Cookies.get('adminSidebarStatus'),
			openedMenu: false,
			withoutAnimation: false
		},
		device: 'desktop',
		language: Cookies.get('adminLanguage') || 'zh'
	},
	mutations: {
		TOGGLE_SIDEBAR: state => {
			if (state.sidebar.opened) {
				Cookies.set('adminSidebarStatus', 1)
			} else {
				Cookies.set('adminSidebarStatus', 0)
			}
			state.sidebar.opened = !state.sidebar.opened
			state.sidebar.withoutAnimation = false
		},
		TOGGLE_MENUOPEN: (state, type) => {
			state.sidebar.openedMenu = type
		},
		CLOSE_SIDEBAR: (state, withoutAnimation) => {
			Cookies.set('adminSidebarStatus', 1)
			state.sidebar.opened = false
			state.sidebar.withoutAnimation = withoutAnimation
		},
		TOGGLE_DEVICE: (state, device) => {
			state.device = device
		},
		SET_LANGUAGE: (state, language) => {
			state.language = language
			Cookies.set('adminLanguage', language)
		}
	},
	actions: {
		toggleSideBar({ commit }) {
			commit('TOGGLE_SIDEBAR')
		},
		closeSideBar({ commit }, { withoutAnimation }) {
			commit('CLOSE_SIDEBAR', withoutAnimation)
		},
		toggleMenuOpen({ commit }, type) {
			commit('TOGGLE_MENUOPEN', type)
		},
		toggleDevice({ commit }, device) {
			commit('TOGGLE_DEVICE', device)
		},
		setLanguage({ commit }, language) {
			commit('SET_LANGUAGE', language)
		}
	}
}

export default app
