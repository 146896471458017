/**
 * Created by Andste on 2018/7/2.
 * base    : 基础业务API
 * buyer   : 买家API
 * seller  : 商家中心API
 * admin   ：后台管理API
 */
const env = process.server
  ? process.env
  : (global.window ? window.__NUXT__.state.env : {});

let dev;

if (location.host.includes('two') || location.host.includes('172.18') || location.host.includes('192.168') || location.host.includes('localhost')) {
  dev = {
    base: 'https://testtwoapifcbase.bzoe.com.cn',
    buyer: 'https://testtwoapifcbuyer.bzoe.com.cn',
    seller: 'https://testtwoapifcseller.bzoe.com.cn',
    admin: 'https://testtwoapifcadmin.bzoe.com.cn'
  };
} else {
  dev = {
    base: 'https://testapifcbase.bzoe.com.cn',
    buyer: 'https://testapifcbuyer.bzoe.com.cn',
    seller: 'https://testapifcseller.bzoe.com.cn',
    admin: 'https://testapifcadmin.bzoe.com.cn'
  };
}
const pro = {
  base: env.API_BASE || 'https://apibase.bzoe.com.cn',
  buyer: env.API_BUYER || 'https://apibuyer.bzoe.com.cn',
  seller: env.API_SELLER || 'https://apiseller.bzoe.com.cn',
  admin: env.API_ADMIN || 'https://apiadmin.bzoe.com.cn',
}

module.exports = {
  dev,
  pro,
};
