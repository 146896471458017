import Vue from 'vue';
import Element from 'element-ui';
import i18n from '../../lang';

Vue.use(Element, {
  size: '',
  i18n: (key: any, value: any) => {
    return i18n.t(key, value);
  }
});
