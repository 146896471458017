import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      scale: 1,
    };
  },
  mounted() {
    window.addEventListener('resize', this.reCalcScale);
    this.reCalcScale();
  },
  methods: {
    reCalcScale() {
      let width = document.body.clientWidth;
      if (width < 1366) width = 1366;
      this.scale = width / 1920 / 1.3;
    }
  }
});
