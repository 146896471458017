/**
 * 检测并区分跳转404页面的两类：
 * 1. 在asyncRouterMap中匹配，但是没有分配给当前登录用户，此时不跳转404，仅仅提示信息
 * 2. 另一类属于不在asyncRouterMap中，比如用户随便输入的'/a'类似的，这类是系统确实没有该路由，直接跳404
 *
 * 另通过path跳转和命名路由name跳转两类不同，通过name跳转不会走404页面，需要处理一下
 *
 */
 import { Message } from 'element-ui'
 import { asyncRouterMap } from '../router'
 import Router from 'vue-router'
 const localRouter = new Router({
   mode: 'history',
   routes: asyncRouterMap.filter(i => !i.path.includes('*'))
 })

 function checkMatchedRoute(matchedRoute, next, NProgress, isNameType) {
   if (matchedRoute.length > 0) {
     Message.error('您没有查看这个模块的权限。')
     NProgress.done()
     next(false)
   } else {
     if(isNameType){
       next({ path: '/404' })
     }else{
       next()
     }
   }
 }

 export default function (to, from, next, NProgress) {
   next();// 注：路由跳转有各种情况，如果有'您没有查看这个模块的权限。'的报错需要先注释掉下面的检查逻辑，打开此处，再完善下面的逻辑
   // if (to.path === '/404') {// 检测通过path跳转的情况
   //   if(to.redirectedFrom&&!to.redirectedFrom.includes('tokens')){// to.redirectedFrom.includes('tokens') 这种情况下是企业跳转站点此时不要做检查
   //     checkMatchedRoute(localRouter.resolve({ path: to.redirectedFrom }).resolved.matched, next, NProgress)
   //   }else{
   //     next()
   //   }
   // } else {
   //   if (to.matched.length === 0) {// 检测通过name跳转的情况
   //     checkMatchedRoute(localRouter.resolve({ name: to.name }).resolved.matched, next, NProgress, 1)
   //   } else {
   //     next()
   //   }
   // }
 }
