export interface XDialogConfig {
  title?: string;
  width?: string;
  wrapperClass?: string;
  modal?: boolean;
  closeOnClickModal?: boolean;
  closeOnPressEscape?: boolean;
  destroyOnClose?: boolean;
  showClose?: boolean;
  displayFooterExtras?: boolean;
  disableCancel?: boolean;
  disableConfirm?: boolean;
  isScroll?:boolean;
  beforeConfirm?: () => boolean | Promise<boolean>;
  beforeCancel?: () => boolean | Promise<boolean>;
  beforeClose?: () => boolean | Promise<boolean>;
  refreshPage?: () => void;
  afterDisplay?: () => void;
  afterDismiss?: () => void;
}

export class XDialogProxy {
  visible = false;

  get displayFooter() {
    const {
      displayFooterExtras,
      disableConfirm,
      disableCancel
    } = this.config;
    if (displayFooterExtras) return true;
    return !disableConfirm || !disableCancel;
  }

  constructor(public config: XDialogConfig = {}) {
    if (config.showClose === undefined) config.showClose = true;
  }

  display(config: XDialogConfig = {}) {
    this.config = {
      ...this.config,
      ...config,
    };
    this.visible = true;
    this.config.afterDisplay && this.config.afterDisplay();
  }

  dismiss() {
    this.visible = false;
    if (this.config.refreshPage) {
      this.config.refreshPage();
    }
  }
}

export const $xDialog = {
  create(config: XDialogConfig = {}) {
    return new XDialogProxy(config);
  }
}
