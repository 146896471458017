import Vue from "vue";

import UIComponents from "@/../ui-components";
import EnComponents from "@/components";
import VueAwesomeSwiper from "vue-awesome-swiper";

import App from "./App.vue";
import router from "./router";
import store from "./store";

import i18n from "./lang"; // Internationalization
import "./icons"; // icon
import "./permission"; // permission control
import * as echarts from "echarts";
import * as filters from "./filters";
import axios from "axios";
import mixin from "./utils/mixin";

import "@/plugins/index";

UIComponents.install(Vue);
EnComponents.install(Vue);
Vue.use(VueAwesomeSwiper);

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

Vue.mixin(mixin);
Vue.config.productionTip = false;
Vue.prototype.$echarts = echarts;
Vue.prototype.$http = axios;
if (location.host.includes("two")) {
  Vue.prototype.$url = ".fcshoptwo.sshlqf.com/";
} else {
  Vue.prototype.$url =
    (location.host.includes("test") ? ".m" : "") + ".fcshop.bzoe.com.cn/";
}

window["app"] = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
