/**
 * 一些常用的基础方法
 * unixToDate    将unix时间戳转换为指定格式
 * dateToUnix    将时间转unix时间戳
 * deepClone     对一个对象进行深拷贝
 * formatPrice   货币格式化
 * secrecyMobile 手机号隐私保护
 * randomString  随机生成指定长度的字符串
 */

/**
 * 将unix时间戳转换为指定格式
 * @param unix   时间戳【秒】
 * @param format 转换格式
 * @returns {*|string}
 */
export function unixToDate(unix, format) {
  if (!unix) return unix;
  let _format = format || "yyyy-MM-dd hh:mm:ss";
  const d = new Date(unix * 1000);
  const o = {
    "M+": d.getMonth() + 1,
    "d+": d.getDate(),
    "h+": d.getHours(),
    "m+": d.getMinutes(),
    "s+": d.getSeconds(),
    "q+": Math.floor((d.getMonth() + 3) / 3),
    S: d.getMilliseconds()
  };
  if (/(y+)/.test(_format))
    _format = _format.replace(
      RegExp.$1,
      (d.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  for (const k in o)
    if (new RegExp("(" + k + ")").test(_format))
      _format = _format.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
  return _format;
}

/**
 * 将时间转unix时间戳
 * @param date
 * @returns {number} 【秒】
 */
export function dateToUnix(date) {
  let newStr = date.replace(/:/g, "-");
  newStr = newStr.replace(/ /g, "-");
  const arr = newStr.split("-");
  const datum = new Date(
    Date.UTC(
      arr[0],
      arr[1] - 1,
      arr[2],
      arr[3] - 8 || -8,
      arr[4] || 0,
      arr[5] || 0
    )
  );
  return parseInt(datum.getTime() / 1000);
}

/**
 * 对一个对象进行深拷贝
 * @param object
 * @returns {*}
 */
export function deepClone(object) {
  let str;
  let newobj = object.constructor === Array ? [] : {};
  if (typeof object !== "object") {
    return object;
  } else if (window.JSON) {
    str = JSON.stringify(object);
    newobj = JSON.parse(str);
  } else {
    for (const i in object) {
      if (object.hasOwnProperty(i)) {
        newobj[i] =
          typeof object[i] === "object" ? deepClone(object[i]) : object[i];
      }
    }
  }
  return newobj;
}

/**
 * 货币格式化
 * @param price
 * @returns {string}
 */
export function formatPrice(price) {
  if (typeof price !== "number") return price;
  return String(Number(price).toFixed(2)).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

/**
 * 手机号隐私保护
 * 隐藏中间四位数字
 * @param mobile
 * @returns {*}
 */
export function secrecyMobile(mobile) {
  mobile = String(mobile);
  if (!/\d{11}/.test(mobile)) {
    return mobile;
  }
  return mobile.replace(/(\d{3})(\d{4})(\d{4})/, "$1****$3");
}

/**
 * 随机生成指定长度的字符串
 * @param length
 * @returns {string}
 */
export function randomString(length = 32) {
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const maxPos = chars.length;
  let _string = "";
  for (let i = 0; i < length; i++) {
    _string += chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return _string;
}

/*根据参数名获得url里的参数*/
export function getUrlKey(name) {
  return (
    decodeURIComponent(
      (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
        location.href
      ) || ["", ""])[1].replace(/\+/g, "%20")
    ) || null
  );
}

export function getAllKey(url) {
  var name, value;
  let obj = {};
  var str = url || location.href; //取得整个地址栏
  var num = str.indexOf("?");
  if (num == -1) {
    return {};
  }
  str = str.substr(num + 1); //取得所有参数   stringvar.substr(start [, length ]
  if (str.indexOf("&") != -1) {
    var arr = str.split("&"); //各个参数放到数组里
    for (var i = 0; i < arr.length; i++) {
      num = arr[i].indexOf("=");
      if (num > 0) {
        name = arr[i].substring(0, num);
        value = arr[i].substr(num + 1);
        this[name] = decodeURI(value);
      }
    }
  } else {
    num = str.indexOf("=");
    if (num > 0) {
      name = str.substring(0, num);
      value = str.substr(num + 1);
      obj[name] = decodeURI(value);
    }
  }
  return obj;
  // console.log(obj, "canshu");
}

/**
 * 计算传秒数的倒计时【天、时、分、秒】
 * @param seconds
 * @returns {{day : *, hours : *, minutes : *, seconds : *}}
 */
export function countTimeDown(seconds) {
  const leftTime = time => {
    if (time < 10) time = "0" + time;
    return time + "";
  };
  return {
    day: leftTime(parseInt(seconds / 60 / 60 / 24, 10)),
    hours: leftTime(parseInt((seconds / 60 / 60) % 24, 10)),
    minutes: leftTime(parseInt((seconds / 60) % 60, 10)),
    seconds: leftTime(parseInt(seconds % 60, 10))
  };
}

/**
 * 计算当前时间到第二天0点的倒计时[秒]
 * @returns {number}
 */
export function theNextDayTime() {
  const nowDate = new Date();
  const time =
    new Date(
      nowDate.getFullYear(),
      nowDate.getMonth(),
      nowDate.getDate() + 1,
      0,
      0,
      0
    ).getTime() - nowDate.getTime();
  return parseInt(time / 1000);
}

export function getnDate(type, dt) {
  type = parseInt(type);
  var arr = [];
  var date = new Date();
  if (dt) {
    date = new Date(dt);
  }
  var hours = date.getHours();
  var secd = date.getSeconds();
  var minu = date.getMinutes();
  var month = date.getMonth() + 1;
  var year = date.getFullYear();
  var gettime = date.getTime();
  var ri = date.getDate();
  if (!type || type === 1) {
    arr.push(
      new Date(year + "/" + month + "/" + ri + " " + "00:00:00").getTime()
    );
    arr.push(
      new Date(year + "/" + month + "/" + ri + " " + "23:59:59").getTime()
    );
    return arr;
  }
  if (type === 2) {
    // 按月
    arr.push(new Date(year, month - 1, 1).getTime());
    arr.push(new Date(year, month, 0).getTime());
    return arr;
  }
  // if (type == 2) { // 按年
  //   let lastDay = new Date(year, 12, 0);
  //   let end = lastDay.getDate();
  //   arr.push(new Date(year + "/" + 1 + "/" + 1 + " " + "00:00:00").getTime());
  //   arr.push(new Date(year + "/" + 12 + "/" + end + " " + "23:59:59").getTime());
  //   return arr
  // }
}

/**
 * 处理时间格式
 * @param format
 * @returns {*|string}
 */
// export function dateHandle(format) {
//   var data = format.substr(0, 19);
//   var newDate = new Date(
//     new Date(data.replace(/T/g, " ").replace(/-/g, "/")).getTime() +
//       8 * 60 * 60 * 1000
//   );
//   var d = new Date(newDate);
//   d =
//     d.getFullYear() +
//     "-" +
//     (d.getMonth() + 1) +
//     "-" +
//     d.getDate() +
//     " " +
//     d.getHours() +
//     ":" +
//     d.getMinutes() +
//     ":" +
//     d.getSeconds();
//   console.log(d);
// }
