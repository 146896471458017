/**
 * 店铺相关API
 */

import request from '@/utils/request'
import md5 from 'js-md5'

/**
 * 添加企业等级
 * @param params
 * @returns {Promise<any>}
 */
export function addLv(params) {
  return request({
    url: 'admin/shops/shopLv',
    method: 'post',
    data:params,
    headers: { 'Content-Type': 'application/json' }
  })
}
/**
 * 充值
 * @param params
 * @returns {Promise<any>}
 */
export function recharge(params,token='') {
  return request({
    url: 'admin/shops/shopBalanceLog/addCompany?'+`shop_id=${params.shop_id}&shop_name=${params.shop_name}&money=${params.money}&remark=${params.remark}`,
    method: 'post',
    headers: { 'Content-Type': 'application/json','token':token }
  })
}
/**
 * 防止重复提交
 * @param params
 * @returns {Promise<any>}
 */
export function noRepetition(params) {
  return request({
    url: 'core/admin/admin-user/get/token',
    method: 'get'
  })
}
/**
 * 编辑企业等级详情
 * @param params
 * @returns {Promise<any>}
 */
export function editLv(id,params) {
  return request({
    url: `admin/shops/shopLv/${id}`,
    method: 'put',
    data:params,
    headers: { 'Content-Type': 'application/json' }
  })
}
/**
 * 获取企业等级详情
 * @param params
 * @returns {Promise<any>}
 */
export function getLevelDetail(id) {
  return request({
    url: `admin/shops/shopLv/${id}`,
    method: 'get'
  })
}
/**
 * 删除企业等级
 * @param params
 * @returns {Promise<any>}
 */
export function delLevel(id) {
  return request({
    url: `admin/shops/shopLv/${id}`,
    method: 'delete'
  })
}
/**
 * 获取店铺列表
 * @param params
 * @returns {Promise<any>}
 */
export function getShopList(params) {
  return request({
    url: 'admin/shops',
    method: 'get',
    params
  })
}
/**
 * 获取所有企业等级列表-不分页
 * @returns {Promise<any>}
 */
export function getLevelList() {
  return request({
    url: 'admin/shops/shopLv/getAllList',
    method: 'get'
  })
}
/**
 * 获取企业等级列表-分页
 * @param params
 * @returns {Promise<any>}
 */
export function getLevelLista(params) {
  return request({
    url: 'admin/shops/shopLv',
    method: 'get',
    params
  })
}
/**
 * 平台发送站内信
 * @param params
 * @returns {Promise<any>}
 */
export function sendMessage(params) {
  return request({
    url: "admin/shops/shop-notice-logs",
    method: 'post',
    data:params
  })
}
/**
 * 平台批量删除企业
 * @param params
 * @returns {Promise<any>}
 */
export function deleteCompany(shop_ids) {
  //let temp = JSON.parse(JSON.stringify(params));
  //temp.password = md5(temp.password);
  //console.log(temp.password,"加密后的密码")
  return request({
    url: `admin/shops/disableBatch/${shop_ids}`,
    method: 'put'
  })
}
/**
 * 平台获取企业信息
 * @param params
 * @returns {Promise<any>}
 */
export function getDetail(shop_id) {

  return request({
    url: `admin/shops/enterprise/${shop_id}`,
    method: 'get'
  })
}
/**
 * 平台编辑企业信息
 * @param params
 * @returns {Promise<any>}
 */
export function editCompany(shop_id,params) {
   let temp = JSON.parse(JSON.stringify(params));
   if(temp.password){
      temp.password = md5(temp.password);
   }
  console.log(temp.password,"加密后的密码")
  return request({
    url: `admin/shops/editEnterprise/${shop_id}`,
    method: 'put',
    data:temp
  })
}
/**
 * 平台企业重置密码
 * @param params
 * @returns {Promise<any>}
 */
export function editEnterprisePassword(shop_id, params) {
  let temp = JSON.parse(JSON.stringify(params));
  if(temp.password){
     temp.password = md5(temp.password);
  }
 console.log(temp.password,"加密后的密码")
 return request({
   url: `admin/shops/editEnterprisePassword/${shop_id}`,
   method: 'put',
   data:temp
 })
}
/**
 * 平台批量启用/停用企业
 * @param params
 * @returns {Promise<any>}
 */
export function openCompany(shop_ids,params) {
  //let temp = JSON.parse(JSON.stringify(params));
  //temp.password = md5(temp.password);
  //console.log(temp.password,"加密后的密码")
  return request({
    url: `admin/shops/editShopUseBatch/${shop_ids}`,
    method: 'put',
    data:params
  })
}
/**
 * 平台添加企业
 * @param params
 * @returns {Promise<any>}
 */
export function addCompany(params) {
  let temp = JSON.parse(JSON.stringify(params));
  // temp.password = md5(temp.password);
  console.log(temp.password,"加密后的密码")
  return request({
    url: 'admin/shops/saveEnterprise',
    method: 'post',
    data:temp
  })
}
/**
 * 获取销售人员列表
 * @param params
 * @returns {Promise<any>}
 */
export function getPerList() {
  return request({
    url: 'admin/shops/getSalesperson',
    method: 'get'
  })
}
//添加供应商
export function addShop(params) {
  return request({
    url: 'admin/shops',
    method: 'post',
    params
  })
}

/**
 * 获取店铺详情
 * @param shop_id
 */
export function getShopDetail(shop_id) {
  return request({
    url: `admin/shops/${shop_id}`,
    method: 'get'
  })
}

/**
 * 关闭店铺
 * @param shop_id
 */
export function closeShop(shop_id) {
  return request({
    url: `admin/shops/disable/${shop_id}`,
    method: 'put'
  })
}

/**
 * 恢复店铺
 * @param shop_id
 */
export function recoverShop(shop_id) {
  return request({
    url: `admin/shops/enable/${shop_id}`,
    method: 'put'
  })
}

/**
 * 修改审核店铺
 * @param shop_id
 * @param params
 */
export function editAuthShop(shop_id, params) {
  return request({
    url: `admin/shops/${shop_id}`,
    method: 'put',
    data: params
  })
}

/**
 * 获取店铺模板列表
 * @param params
 * @returns {Promise<any>}
 */
export function getShopThemeList(params) {
  return request({
    url: 'admin/shops/themes',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 添加店铺模板
 * @param params
 * @returns {*}
 */
export function addShopTheme(params) {
  return request({
    url: 'admin/shops/themes',
    method: 'post',
    data: params
  })
}

/**
 * 获取店铺模板详情
 * @param id
 * @returns {Promise<any>}
 */
export function getShopThemeDetail(id) {
  return request({
    url: `admin/shops/themes/${id}`,
    method: 'get'
  })
}

/**
 * 修改店铺模板
 * @param id
 * @param params
 * @returns {*}
 */
export function editShopTheme(id, params) {
  return request({
    url: `admin/shops/themes/${id}`,
    method: 'put',
    data: params
  })
}

/**
 * 删除店铺模板
 * @param id
 * @returns {*}
 */
export function deleteShopTheme(id) {
  return request({
    url: `admin/shops/themes/${id}`,
    method: 'delete'
  })
}

/**
 * 搜索企业下边的站点
 * @param id
 * @returns {*}
 */
export function getShopChilds(params) {
  return request({
    url: `/admin/shops/getShopChilds`,
    method: 'get',
    params
  })
}

/**
 * 获取企业的权限（销售开卡）
 */
export function getEnterRules(params) {
  return request({
    url: '/admin/shops/getShopExtAuth',
    method: 'get',
    params
  })
}

/**
 * 编辑企业权限（销售开卡）
 */
export function editEnterRules(params) {
  return request({
    url: '/admin/shops/editShopExtAuth',
    method: 'put',
    data: params
  })
}
