/**
 * Created by jiachenpan on 16/11/18.
 */

export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (('' + time).length === 10) time = parseInt(time) * 1000
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    if (key === 'a') return ['一', '二', '三', '四', '五', '六', '日'][value - 1]
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}
/**
 * 数组对象深拷贝
 * @param obj
 * @returns {*}
 */
export function cloneObj(obj) {
  let str = null
  let newobj = obj.constructor === Array ? [] : {}
  if (typeof obj !== 'object') {
    return
  } else if (window && window.JSON) {
    str = JSON.stringify(obj) // 系列化对象
    newobj = JSON.parse(str) // 还原
  } else {
    for (var i in obj) {
      newobj[i] = typeof obj[i] === 'object' ? cloneObj(obj[i]) : obj[i]
    }
  }
  return newobj
}


export function formatTime(time, option) {
  time = +time * 1000
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) { // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分'
  }
}

// 格式化时间
export function getQueryObject(url) {
  url = url == null ? window.location.href : url
  const search = url.substring(url.lastIndexOf('?') + 1)
  const obj = {}
  const reg = /([^?&=]+)=([^?&=]*)/g
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1)
    let val = decodeURIComponent($2)
    val = String(val)
    obj[name] = val
    return rs
  })
  return obj
}

/**
 *get getByteLen
 * @param {Sting} val input value
 * @returns {number} output value
 */
export function getByteLen(val) {
  let len = 0
  for (let i = 0; i < val.length; i++) {
    if (val[i].match(/[^\x00-\xff]/ig) != null) {
      len += 1
    } else { len += 0.5 }
  }
  return Math.floor(len)
}

export function cleanArray(actual) {
  const newArray = []
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i])
    }
  }
  return newArray
}

export function param(json) {
  if (!json) return ''
  return cleanArray(Object.keys(json).map(key => {
    if (json[key] === undefined) return ''
    return encodeURIComponent(key) + '=' +
            encodeURIComponent(json[key])
  })).join('&')
}

export function param2Obj(url) {
  const search = url.split('?')[1]
  if (!search) {
    return {}
  }
  return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
}

export function html2Text(val) {
  const div = document.createElement('div')
  div.innerHTML = val
  return div.textContent || div.innerText
}

export function objectMerge(target, source) {
  /* Merges two  objects,
     giving the last one precedence */

  if (typeof target !== 'object') {
    target = {}
  }
  if (Array.isArray(source)) {
    return source.slice()
  }
  Object.keys(source).forEach((property) => {
    const sourceProperty = source[property]
    if (typeof sourceProperty === 'object') {
      target[property] = objectMerge(target[property], sourceProperty)
    } else {
      target[property] = sourceProperty
    }
  })
  return target
}

export function scrollTo(element, to, duration) {
  if (duration <= 0) return
  const difference = to - element.scrollTop
  const perTick = difference / duration * 10
  setTimeout(() => {
    console.log(new Date())
    element.scrollTop = element.scrollTop + perTick
    if (element.scrollTop === to) return
    scrollTo(element, to, duration - 10)
  }, 10)
}

export function toggleClass(element, className) {
  if (!element || !className) {
    return
  }
  let classString = element.className
  const nameIndex = classString.indexOf(className)
  if (nameIndex === -1) {
    classString += '' + className
  } else {
    classString = classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length)
  }
  element.className = classString
}

export const pickerOptions = [
  {
    text: '今天',
    onClick(picker) {
      const end = new Date()
      const start = new Date(new Date().toDateString())
      end.setTime(start.getTime())
      picker.$emit('pick', [start, end])
    }
  }, {
    text: '最近一周',
    onClick(picker) {
      const end = new Date(new Date().toDateString())
      const start = new Date()
      start.setTime(end.getTime() - 3600 * 1000 * 24 * 7)
      picker.$emit('pick', [start, end])
    }
  }, {
    text: '最近一个月',
    onClick(picker) {
      const end = new Date(new Date().toDateString())
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      picker.$emit('pick', [start, end])
    }
  }, {
    text: '最近三个月',
    onClick(picker) {
      const end = new Date(new Date().toDateString())
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
      picker.$emit('pick', [start, end])
    }
  }]

export function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90
  } else {
    return new Date(new Date().toDateString())
  }
}

export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function() {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔last小于设定时间间隔wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function(...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}

export function deepClone(source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'shallowClone')
  }
  const targetObj = source.constructor === Array ? [] : {}
  Object.keys(source).forEach((keys) => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = source[keys].constructor === Array ? [] : {}
      targetObj[keys] = deepClone(source[keys])
    } else {
      targetObj[keys] = source[keys]
    }
  })
  return targetObj
}

export function formatJson(filterVal, jsonData) {
  return jsonData.map(v => filterVal.map(j => {
    if (j === 'timestamp') {
      return parseTime(v[j])
    } else {
      return v[j]
    }
  }))
}

//导出
export function handleDownload(tableData = [], tHeaders = [], filterVals = [], name = '数据表格') {
  import('@/vendor/Export2Excel2').then(excel => {
    const tHeader = tHeaders
    const filterVal = filterVals
    const list = tableData
    const data = formatJson(filterVal, list)
    excel.export_json_to_excel({
      header: tHeader,
      data,
      filename: name + ( parseTime(new Date(),'{y}-{m}-{d}') ),
      autoWidth: true,
      bookType: 'xlsx'
    })
  })
}

export function mergeSkuList(data = [], type) {
  return data.reduce((results, item) => {
    let sku_list = item['order_items_dtos'] || item.sku_list;

    if (!sku_list) return;

    switch (type) {
      case 1:
        const {
          enterprise_goods_price,
          enterprise_freight_price,
          goods_price,
          freight_price
        } = item;

        item.price = 0;
        item.enterprise_total_price = 0;
        item.revise_price = 0;

        // 分销利润
        item.distribution_profit = (enterprise_goods_price + enterprise_freight_price) - (goods_price + freight_price);

        sku_list = sku_list.map((goods) => {
          if (!goods.state) goods.state = 0;

          item.price += goods.price * goods.num;
          item.enterprise_total_price += goods.enterprise_price * goods.num;
          item.revise_price += goods.revise_price * goods.num;


          goods.delivery_json = JSON.parse(goods.delivery_json);

          return goods;
        });
        break;
      case 2:
        item.order_total_price = 0;
        item.enterprise_purchase_price = 0;
        item.revise_price = 0;

        sku_list = sku_list.map(goods => {
          if (!goods.state) goods.state = 0;

          goods.shop_purchase_price = goods.shop_purchase_price || 0;

          item.order_total_price += goods.shop_purchase_price * goods.num;
          item.enterprise_purchase_price += goods.enterprise_purchase_price * goods.num;
          item.revise_price += goods.revise_price * goods.num;

          goods.delivery_json = JSON.parse(goods.delivery_json);

          return goods;
        })
        break;
      case 3:
        item.order_total_price = 0;

        sku_list = sku_list.map(goods => {
          goods.cost_price = goods.cost_price || 0;

          goods.delivery_json = JSON.parse(goods.delivery_json)
          goods.jiage = goods.supplier_purchase_price;
          item.order_total_price += goods.supplier_purchase_price * goods.num;

          return goods;
        })
        break;
    }
    if (type === 1) {
      results.push(
        ...sku_list.map((row, index) => {
          let span = { rowspan: 0, colspan: 0 };

          if (index === 0) {
            span = {
              rowspan: sku_list.length,
              colspan: 1,
            };
          }
          // console.log(item.order_remark.replaceAll("111", "\n"))
          return {
            span,
            sn: item.sn,
            enterprise_name: item.enterprise_name,
            trade_sn: item.trade_sn,
            member_name: item.member_name,
            login_account: item.login_account,
            mobile:item.mobile,
            create_time: item.create_time,
            supplier_order_price: item.supplier_order_price,
            account_pay_money: item.account_pay_money,
            discount_card_voucher_price: item.discount_card_voucher_price,
            weixin_pay_money: item.weixin_pay_money,
            order_price: item.order_price,
            order_remark: item.order_remark,
            remark_update_date: item.remark_update_date,
            trade_status_text: item.trade_status_text,
            hangup_status: item.hangup_status,
            order_hangup_status:row.hangup_status, // 订单挂起状态
            order_status_text: item.order_status_text,
            enterprise_total_price: item.enterprise_total_price,
            enterprise_purchase_price: item.enterprise_purchase_price,
            price: row.price,
            revise_price: item.revise_price,
            order_total_price: item.order_total_price,
            goods_image: row.goods_image || row.image,
            goods_name: row.name,
            mall_type: item.mall_type,
            shop_combo_id:item.shop_combo_id,
            goods_alias: row.goods_alias,
            goods_num: row.num,
            goods_type: row.goods_type,
            shop_price: row.shop_price,
            revise_exchange_money: row.revise_exchange_money,
            revise_exchange_point: row.revise_exchange_point,
            supplier_price: row.supplier_price,
            exchange_money: row.exchange_money,
            exchange_point: row.exchange_point,
            delivery: row.delivery_json && row.delivery_json[0],
            service_status: row.service_status,
            state: row.state,
            logistics_status_text:row.logistics_status_text,
            seller_name: row.seller_name,
            order_source: row.order_source,
            jiage: row.jiage,
            shop_purchase_price: row.shop_purchase_price,
            original_price: row.original_price,
            order_status: item.order_status,
            shop_combo_order_price: item.shop_combo_order_price,
            distribution_profit: item.distribution_profit,
            order_original: item.enterprise_name + '-' + item.shop_name,
            platform_profit: (item.platform_price - item.cost_price).toFixed(2),
            remark: item.remark,
            consignee_name: item.consignee_name,
            consignee_mobile: item.consignee_mobile,
            consignee_province: item.consignee_province,
            consignee_city: item.consignee_city || "",
            consignee_county: item.consignee_county || "",
            consignee_town: item.consignee_town || "",
            consignee_address: item.consignee_address || "",
            ship_name: item.ship_name,
            ship_mobile: item.ship_mobile,
            ship_province: item.ship_province,
            ship_city: item.ship_city,
            ship_county: item.ship_county||"",
            ship_town: item.ship_town||"",
            ship_addr: item.ship_addr||"",
            platform_total_price: item.platform_total_price,
            platform_price: item.platform_price,
            platform_freight_price: item.platform_freight_price,
            jd_cancel_status: item.jd_cancel_status,
            trade_form: item.trade_form,
            delivery_method: item.delivery_method,
          }
        })
      );
    }


    return results;
  }, []);
}
