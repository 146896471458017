import FloorTagsPicker from './FloorTagsPicker'
import FloorTitlePicker from './FloorTitlePicker'
import CategoryPicker from './CategoryPicker'
import SkuSelector from './SkuSelector'
import GradeEditor from './GradeEditor'
import ShopPicker from './ShopPicker'
import UE from './UE'
import ElDialogXDialog from './ElDialogXDialog'

const components = {
	FloorTagsPicker,
	FloorTitlePicker,
	GradeEditor,
	ShopPicker,
	ElDialogXDialog
}

components.install = function(Vue) {
	Object.keys(components).forEach(key => {
		key !== 'install' && Vue.component(components[key].name, components[key])
	})
}

export default components

export {
	CategoryPicker,
	SkuSelector,
	FloorTagsPicker,
	FloorTitlePicker,
	GradeEditor,
	ShopPicker,
	UE
}
