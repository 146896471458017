import Vue from 'vue'
import Router from 'vue-router'
/* Layout */
import Layout from '@/views/layout/Layout'

Vue.use(Router)

/**
 * hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
 * redirect: noredirect           if `redirect:noredirect` will no redirct in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
    noCache: true                if true ,the page will no be cached(default is false)
  }
 **/
export const constantRouterMap = [{
  path: '/login',
  // component: () => import('@/views/login/index'),
  component: () => import('@/pages/auth/pages/login'),
  hidden: true
},
{
  path: '/404',
  component: () => import('@/views/errorPage/404'),
  hidden: true
},
{
  path: '/401',
  component: () => import('@/views/errorPage/401'),
  hidden: true
},
  // {
  //   path: '',
  //   component: Layout,
  //   redirect: 'dashboard',
  //   children: [
  //     {
  //       path: 'dashboard',
  //       component: () => import('@/views/dashboard/index'),
  //       name: 'dashboard',
  //       meta: { title: 'dashboard', icon: 'shouye' }
  //       },
  //     ]
  // }
]

export default new Router({
  mode: 'history',
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRouterMap
})

// 分销
// const disRouterMap = {
//   path: '/distribution',
//   component: Layout,
//   redirect: '/distribution/extract-tpl',
//   name: 'distribution',
//   meta: {
//     title: 'distribution',
//     icon: 'development-manage'
//   },
//   children: [{
//       path: '/distribution/extract-tpl',
//       component: () => import('@/views/distribution/extractTpl/index'),
//       redirect: '/distribution/extract-tpl/upgrade-logs',
//       name: 'extractTpl',
//       meta: {
//         title: 'extractTpl'
//       },
//       children: [{
//           path: 'per-accomplishment-tpl',
//           component: () => import('@/views/distribution/extractTpl/perAccomplishmentTpl'),
//           name: 'perAccomplishmentTpl',
//           meta: {
//             title: 'perAccomplishmentTpl'
//           }
//         },
//         {
//           path: 'upgrade-logs',
//           component: () => import('@/views/distribution/extractTpl/upgradeLogs'),
//           name: 'upgradeLogs',
//           meta: {
//             title: 'upgradeLogs'
//           }
//         }
//       ]
//     },
//     {
//       path: '/distribution/distributor',
//       component: () => import('@/views/distribution/distributor/index'),
//       redirect: '/distribution/distributor/distributor-list',
//       name: 'distributor',
//       meta: {
//         title: 'distributor'
//       },
//       children: [{
//           path: 'distributor-list',
//           component: () => import('@/views/distribution/distributor/distributorList'),
//           name: 'distributorList',
//           meta: {
//             title: 'distributorList'
//           }
//         },
//         {
//           path: 'distributor-statistics',
//           component: () => import('@/views/distribution/distributor/distributorStatistics'),
//           name: 'distributorStatistics',
//           meta: {
//             title: 'distributorStatistics'
//           },
//           hidden: true
//         }
//       ]
//     },
//     {
//       path: '/distribution/achievement',
//       component: () => import('@/views/distribution/achievement/index'),
//       redirect: '/distribution/achievement/achievement-list',
//       name: 'achievement',
//       meta: {
//         title: 'achievement'
//       },
//       children: [{
//           path: 'achievement-list',
//           component: () => import('@/views/distribution/achievement/achievementList'),
//           name: 'achievementList',
//           meta: {
//             title: 'achievementList'
//           }
//         },
//         {
//           path: 'bill-list',
//           component: () => import('@/views/distribution/achievement/billList'),
//           name: 'billList',
//           meta: {
//             title: 'billList'
//           },
//           hidden: true
//         },
//         {
//           path: 'bill-details',
//           component: () => import('@/views/distribution/achievement/billDetails'),
//           name: 'billDetails',
//           meta: {
//             title: 'billDetails'
//           },
//           hidden: true
//         }
//       ]
//     },
//     {
//       path: '/distribution/put-forward',
//       component: () => import('@/views/distribution/putforward/index'),
//       redirect: '/distribution/put-forward/put-forward-settings',
//       name: 'putforwardManage',
//       meta: {
//         title: 'putforwardManage'
//       },
//       children: [{
//           path: 'put-forward-settings',
//           component: () => import('@/views/distribution/putforward/putforwardSettings'),
//           name: 'putforwardSettings',
//           meta: {
//             title: 'putforwardSettings'
//           }
//         },
//         {
//           path: 'put-forward-apply',
//           component: () => import('@/views/distribution/putforward/putforwardApply'),
//           name: 'putforwardApply',
//           meta: {
//             title: 'putforwardApply'
//           }
//         }
//       ]
//     }
//   ]
// }

const asyncRouters = [
  // 首页管理
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    name: 'dashbo',
    meta: {
      title: 'dashbo',
      icon: 'home'
    },
    children: [
      {
        path: 'home',
        component: () => import('@/views/dashboard/index'),
        name: 'dashboard',
        meta: {
          title: 'dashboard'
        },
      },
      {
        path: 'handleLog',
        component: () => import('@/views/dashbo/handle'),
        name: 'handle',
        meta: {
          title: 'handle'
        },
      },
      {
        path: 'loginLog',
        component: () => import('@/views/dashbo/login'),
        name: 'login',
        meta: {
          title: 'login'
        },
      },
    ]
  },
  // 企业管理
  {
    path: '/enterp',
    component: Layout,
    redirect: '/enterp/enterplist',
    name: 'enterp',
    meta: {
      title: 'enterp',
      icon: 'enterprise'
    },
    children: [{
      path: '/enterp/enterplist',
      component: () => import('@/views/enterp/enterplist'),
      name: 'enterplist',
      meta: {
        title: 'enterplist'
      }
    },
    {
      path: '/enterp/levelmanege',
      component: () => import('@/views/enterp/levelmanege'),
      name: 'levelmanege',
      meta: {
        title: 'levelmanege'
      }
    },
    {
      path: '/enterpadd',
      component: () => import('@/views/enterp/enterpadd'),
      name: 'enterpadd',
      hidden: true,
      meta: {
        title: 'enterpadd',
        activeNames: ['enterplist']
      }
    },
    ]
  },
  // 供应商管理
  {
    path: '/supplier',
    component: Layout,
    redirect: '/supplier/supplier-list',
    name: 'supplier',
    meta: {
      title: 'supplier',
      icon: 'supplier'
    },
    children: [{
      path: 'supplier-list',
      component: () => import('@/views/supplier/supplierList'),
      name: 'supplierList',
      meta: {
        title: 'supplierList'
      }
    },
    {
      path: 'supplier-edit/:id',
      component: () => import('@/views/supplier/supplierEdit'),
      name: 'supplierEdit',
      hidden: true,
      meta: {
        title: 'supplierEdit'
      }
    },
    {
      path: 'retentionChange/:id',
      component: () => import('@/views/supplier/retentionChange'),
      name: 'retentionChange',
      meta: {
        title: 'retentionChange',
        activeNames: ['supplierList']
      },
      hidden: true
    },
    ]
  },
  // 商品管理
  {
    path: '/goods',
    component: Layout,
    redirect: '/goods/goods-list',
    name: 'goods',
    meta: {
      title: 'goods',
      icon: 'goods'
    },
    children: [{
      path: 'goods-list',
      component: () => import('../pages/goods/goods-list/goods-list'),
      name: 'goodsList',
      meta: {
        title: 'goodsList'
      }
    },
    {
      path: 'goods-publish',
      component: () => import('@/views/goods/goodsPublish'),
      name: 'goodsPublish',
      hidden: true,
      meta: {
        title: 'goodsPublish',
        noCache: true
      }
    },
    {
      path: 'goods-audit-detail',
      component: () => import('@/views/goods/goodsAuditDetail'),
      name: 'goodsAuditDetail',
      hidden: true,
      meta: {
        title: 'goodsAuditDetail',
        noCache: true,
        activeNames: ['goodsAudit']
      }
    },
    {
      path: 'goods-audit',
      component: () => import('../pages/goods/goods-audit/pages/goods-audit'),
      name: 'goodsAudit',
      meta: {
        title: 'goodsAudit'
      }
    },
    // 本次更新不更新运费设置以及智能运营功能，需要更新时打开注释（智能运营）
    // {
    //   path: 'intel-operation',
    //   component: () => import('../pages/goods/intel-operation/intel-operation'),
    //   name: 'intelOperation',
    //   meta: {
    //     title: 'intelOperation'
    //   }
    // },
    {
      path: 'category-list',
      component: () => import('@/views/goods/goods-setting/categoryList'),
      name: 'categoryList',
      meta: {
        title: 'categoryList'
      }
    },
    {
      path: 'category-params/:id',
      component: () => import('@/views/goods/goods-setting/categoryParams'),
      name: 'categoryParams',
      hidden: true,
      meta: {
        title: 'categoryParams',
        noCache: true
      }
    },
    {
      path: 'brand-list',
      component: () => import('@/views/goods/goods-setting/brandList'),
      name: 'brandList',
      meta: {
        title: 'brandList'
      }
    },
    {
      path: 'goods-price-change',
      name: 'goodsPriceChange',
      component: () => import('@/pages/goods/goods-price-change/goodsPriceChange'),
      meta: {
        title: 'goodsPriceChange'
      }
    },
    ]
  },
  // 页面管理集市
  {
    path: '/page',
    component: Layout,
    redirect: '/page/floor',
    name: 'page',
    meta: {
      title: 'page',
      icon: 'market'
    },
    children: [{
      path: 'floor',
      component: () => import('@/views/page/pc-decoration/pcFloorManage'),
      name: 'pcFloorManage',
      meta: {
        title: 'pcFloorManage'
      }
    },
    {
      path: 'focus',
      component: () => import('@/views/page/pc-decoration/pcFocusManage'),
      name: 'pcFocusManage',
      meta: {
        title: 'pcFocusManage',
        activeNames: ['pcFloorManage']
      },
      hidden: true
    },
    {
      path: 'site-menu',
      component: () => import('@/views/page/page-setting/pcSiteMenu'),
      name: 'pcSiteMenu',
      meta: {
        title: 'pcSiteMenu'
      }
    }
    ]
  },
  // 订单管理
  {
    path: '/order',
    component: Layout,
    name: 'order',
    redirect: '/order/order-lis',
    meta: {
      title: 'order',
      icon: 'order'
    },
    children: [{
      path: 'order-lis',
      component: () => import('@/views/order/orderlist/orderList'),
      name: 'orderLis',
      meta: {
        title: 'orderLis'
      }
    },
    {
      path: 'serve-orderlist',
      component: () => import('@/views/order/orderlist/serveorderlist'),
      name: 'serveorderlist',
      meta: {
        title: 'serveorderlist'
      }
    },
    {
      path: 'detail/:sn?',
      component: () => import('@/views/order/orderlist/orderDetail'),
      name: 'orderDetail',
      hidden: true,
      meta: {
        title: 'orderDetail',
        activeNames: ['orderLis']
      }
    },
    {
      path: 'order_detail/:sn?',
      component: () => import('@/views/order/orderlist/orderDetailTwo'),
      name: 'orderDetailTwo',
      hidden: true,
      meta: {
        title: 'orderDetailTwo',
        activeNames: ['orderLis']
      }
    },
    {
      path: 'children_order_detail/:sn?',
      component: () => import('@/views/order/orderlist/childrenOrderDetail'),
      name: 'childrenOrderDetail',
      meta: {
        activeNames: ['orderLis']
      },
      hidden: true,
    },
    {
      path: 'power-List',
      component: () => import('@/views/order/orderlist/powerList'),
      name: 'powerList',
      meta: {
        title: 'powerList'
      }
    },
    {
      path: 'powerorderDetail/:sn?',
      component: () => import('@/views/order/orderlist/powerorderDetail'),
      name: 'powerorderDetail',
      hidden: true,
      meta: {
        title: 'powerorderDetail',
        activeNames: ['powerList']
      }
    }
    ]
  },

  // // 对账管理
  // {
  //   path: '/account',
  //   component: Layout,
  //   redirect: '/account/account-list',
  //   name: 'account',
  //   meta: {
  //     title: 'account',
  //     icon: 'order-manage'
  //   },
  //   children: [{
  //       path: 'account-list',
  //       component: () => import('@/views/account/accountList'),
  //       name: 'accountList',
  //       meta: {
  //         title: 'accountList'
  //       }
  //     },
  //     {
  //       path: 'accountchange/:id',
  //       component: () => import('@/views/account/accountchange'),
  //       name: 'accountchange',
  //       meta: {
  //         title: 'accountchange'
  //       },
  //       hidden: true
  //     },
  //     {
  //       path: 'account-detail/:id/:type',
  //       component: () => import('@/views/account/accountDetail'),
  //       name: 'accountDetail',
  //       meta: {
  //         title: 'accountDetail',
  //         noCache: true
  //       },
  //       hidden: true
  //     },
  //   ]
  // },

  // 财务管理
  {
    path: '/finance',
    component: Layout,
    redirect: '/finance/AccountManage',
    name: 'finance',
    meta: {
      title: 'finance',
      icon: 'finance'
    },
    children: [
      {
        path: '/finance/AccountManage',
        component: () => import('@/views/finance/AccountManage/'),
        redirect: '/finance/AccountManage/accountreviw',
        name: 'AccountManage',
        meta: {title: 'AccountManage'},
        children: [
          {
            path: 'accountreviw',
            component: () => import('@/views/finance/AccountManage/accountreviw'),
            name: 'accountreviw',
            meta: {
              title: 'accountreviw'
            }
          },
          {
            path: 'signreviw',
            component: () => import('@/views/finance/AccountManage/signreviw'),
            name: 'signreviw',
            meta: {
              title: 'signreviw'
            }
          },
          {
            path: 'rechargeRecord',
            component: () => import('@/views/finance/AccountManage/rechargeRecord'),
            name: 'rechargeRecord',
            meta: {
              title: 'rechargeRecord'
            }
          },
          {
            path: 'enterpriseManual',
            component: () => import('@/views/finance/AccountManage/enterpriseManual'),
            name: 'enterpriseManual',
            meta: {
              title: 'enterpriseManual'
            }
          },
        ]
      },
      {
        path: '/finance/rechargeriew',
        component: () => import('@/views/finance/rechargeriew/'),
        redirect: '/finance/rechargeriew/rechagriew',
        name: 'rechargeriew',
        meta: {title: 'rechargeriew'},
        children: [
          {
            path: 'rechagriew',
            component: () => import('@/views/finance/rechargeriew/rechagriew'),
            name: 'rechagriew',
            meta: {
              title: 'rechagriew'
            }
          },
          {
            path: 'chagriew',
            component: () => import('@/views/finance/rechargeriew/chagriew'),
            name: 'chagriew',
            meta: {
              title: 'chagriew'
            }
          },
          {
            path: 'paymentaccount',
            component: () => import('@/views/finance/rechargeriew/paymentaccount'),
            name: 'paymentaccount',
            meta: {
              title: 'paymentaccount'
            }
          },
        ]
      },
      {
        path: '/finance/invoice',
        component: () => import('@/views/finance/invoice/'),
        redirect: '/finance/invoice/terraceInvoice',
        name: 'invoice',
        meta: {title: 'invoice'},
        children: [
          {
            path: 'terraceInvoice',
            component: () => import('@/views/finance/invoice/terraceInvoice'),
            name: 'terraceInvoice',
            meta: {
              title: 'terraceInvoice'
            }
          },
          {
            path: 'enterpriseInvoice',
            component: () => import('@/views/finance/invoice/enterpriseInvoice'),
            name: 'enterpriseInvoice',
            meta: {
              title: 'enterpriseInvoice'
            }
          },
          {
            path: 'requestInvoice',
            component: () => import('@/views/finance/invoice/requestInvoice'),
            name: 'requestInvoice',
            hidden: true,
            meta: {title: 'requestInvoice', activeNames: ['terraceInvoice']},
          },
          {
            path: 'administer',
            component: () => import('@/views/finance/invoice/administer'),
            name: 'administer',
            hidden: true,
            meta: {
              title: 'administer',
              activeNames: ['terraceInvoice']
            }
          },
          {
            path: 'Invoicemangerjilu',
            component: () => import('@/views/finance/invoice/Invoicemangerjilu'),
            name: 'Invoicemangerjilu',
            hidden: true,
            meta: {
              title: 'Invoicemangerjilu',
              activeNames: ['terraceInvoice']
            }
          },
          {
            path: 'Invoicemangerdetail',
            component: () => import('@/views/finance/invoice/Invoicemangerdetail'),
            name: 'Invoicemangerdetail',
            hidden: true,
            meta: {
              title: 'Invoicemangerdetail',
              activeNames: ['terraceInvoice']
            }
          },
          {
            path: 'auditHistoryDetail',
            component: () => import('@/views/finance/invoice/auditHistoryDetail'),
            name: 'auditHistoryDetail',
            hidden: true,
            meta: {
              title: 'auditHistoryDetail',
              activeNames: ['enterpriseInvoice']
            }
          },
          {
            path: 'auditHistoryDetailSeller',
            component: () => import('@/views/finance/invoice/auditHistoryDetail'),
            name: 'auditHistoryDetailSeller',
            hidden: true,
            meta: {
              title: 'auditHistoryDetailSeller',
              activeNames: ['terraceInvoice']
            }
          },
        ]
      }

    ]
  },

  // 统计
  {
    path: '/statistics',
    component: Layout,
    redirect: '/statistics/member-analysis/order-amount',
    name: 'statistics',
    meta: {
      title: 'statistics',
      icon: 'data'
    },
    children: [

      {
        path: 'census',
        component: () => import('@/views/statistics/sale/census'),
        name: 'census',
        meta: {
          title: 'census'
        }
      },
      // 流量数据
      // {
      //   path: 'flow',
      //   component: () => import('@/views/statistics/sale/flowData'),
      //   name: 'flowData',
      //   meta: {
      //     title: 'flowData'
      //   }
      // },
      {
        path: 'revenue',
        component: () => import('@/views/statistics/sale/revenueData'),
        name: 'revenueData',
        meta: {
          title: 'revenueData'
        }
      }

    ]
  },

  // 消息
  {
    path: '/news',
    component: Layout,
    redirect: '/news/newslist',
    name: 'news',
    meta: {
      title: 'news',
      icon: 'notify'
    },
    children: [

      {
        path: '/news/newslist',
        component: () => import('@/views/news/newslist'),
        name: 'newslist',
        meta: {
          title: 'newslist'
        }
      },
      // {
      //   path: '/news/platinform',
      //   component: () => import('@/views/news/platinform'),
      //   name: 'platinform',
      //   meta: {
      //     title: 'platinform'
      //   }
      // },
      // {
      //   path: '/news/protocol',
      //   component: () => import('@/views/news/protocol'),
      //   name: 'protocol',
      //   meta: {
      //     title: 'protocol'
      //   }
      // },
      // {
      //   path: '/news/logistics',
      //   component: () => import('@/views/news/logistics'),
      //   name: 'logistics',
      //   meta: {
      //     title: 'logistics'
      //   }
      // },
      // {
      //   path: '/news/safemanege',
      //   component: () => import('@/views/news/safemanege'),
      //   name: 'safemanege',
      //   meta: {
      //     title: 'safemanege'
      //   }
      // },

      {
        path: '/news/nnewsmange',
        component: () => import('@/views/news/nnewsmange'),
        name: 'nnewsmange',
        hidden: true,
        meta: {
          title: 'nnewsmange'
        }
      },
      {
        path: '/news/mnewsmange',
        component: () => import('@/views/news/mnewsmange'),
        name: 'mnewsmange',
        hidden: true,
        meta: {
          title: 'mnewsmange'
        }
      },
    ]
  },

  //   // 店铺管理
  //   {
  //     path: '/shop',
  //     component: Layout,
  //     redirect: '/shop/shop-list',
  //     name: 'shop',
  //     meta: {
  //       title: 'shop',
  //       icon: 'shop-manage'
  //     },
  //     children: [{
  //         path: 'shop-list',
  //         component: () => import('@/views/shop/shop-manage/shopList'),
  //         name: 'shopList',
  //         meta: {
  //           title: 'shopList'
  //         }
  //       },
  //       {
  //         path: 'shop-audit',
  //         component: () => import('@/views/shop/shop-manage/shopAudit'),
  //         name: 'shopAudit',
  //         meta: {
  //           title: 'shopAudit'
  //         }
  //       },
  //       {
  //         path: 'edit/:id',
  //         component: () => import('@/views/shop/shop-manage/shopEdit'),
  //         name: 'shopEdit',
  //         hidden: true,
  //         meta: {
  //           title: 'shopEdit'
  //         }
  //       },
  //       {
  //         path: 'settlement',
  //         component: () => import('@/views/shop/settlement-manage/settlement'),
  //         name: 'settlement',
  //         meta: {
  //           title: 'settlement'
  //         }
  //       },
  //       {
  //         path: 'settlement/:sn',
  //         component: () => import('@/views/shop/settlement-manage/settlementList'),
  //         name: 'settlementList',
  //         hidden: true,
  //         meta: {
  //           title: 'settlementList'
  //         }
  //       },
  //       {
  //         path: 'detail/:id',
  //         component: () => import('@/views/shop/settlement-manage/settlementDetail'),
  //         name: 'settlementDetail',
  //         hidden: true,
  //         meta: {
  //           title: 'settlementDetail'
  //         }
  //       }
  //
  //     ]
  //   },

  // 促销管理
  // {
  //   path: '/promotions',
  //   component: Layout,
  //   redirect: '/promotions/group-buy-list',
  //   name: 'promotions',
  //   meta: {
  //     title: 'promotions',
  //     icon: 'promotions-manage'
  //   },
  //   children: [{
  //       path: 'group-buy-list',
  //       component: () => import('@/views/promotions/group-buy-manage/groupBuyList'),
  //       name: 'groupBuyList',
  //       meta: {
  //         title: 'groupBuyList'
  //       }
  //     },
  //     {
  //       path: 'group-buy-category',
  //       component: () => import('@/views/promotions/group-buy-manage/groupBuyCategory'),
  //       name: 'groupBuyCategory',
  //       meta: {
  //         title: 'groupBuyCategory'
  //       }
  //     },
  //     {
  //       path: 'group-buy-goods/:id',
  //       component: () => import('@/views/promotions/group-buy-manage/groupBuyGoods'),
  //       name: 'groupBuyGoods',
  //       hidden: true,
  //       meta: {
  //         title: 'groupBuyGoods'
  //       }
  //     },
  //     {
  //       path: 'group-buy-goods-info/:id',
  //       component: () => import('@/views/promotions/group-buy-manage/groupBuyGoodsInfo'),
  //       name: 'groupBuyGoodsInfo',
  //       hidden: true,
  //       meta: {
  //         title: 'groupBuyGoodsInfo'
  //       }
  //     },
  //     {
  //       path: 'points-classify',
  //       component: () => import('@/views/promotions/points-mall-manage/pointsClassify'),
  //       name: 'pointsClassify',
  //       meta: {
  //         title: 'pointsClassify'
  //       }
  //     },
  //     {
  //       path: 'points-goods',
  //       component: () => import('@/views/promotions/points-mall-manage/pointsGoods'),
  //       name: 'pointsGoods',
  //       meta: {
  //         title: 'pointsGoods'
  //       }
  //     },
  //     {
  //       path: 'seckill-list',
  //       component: () => import('@/views/promotions/seckill-manage/seckillList'),
  //       name: 'seckillList',
  //       meta: {
  //         title: 'seckillList'
  //       }
  //     },
  //     {
  //       path: 'seckill-list/audit/:id',
  //       component: () => import('@/views/promotions/seckill-manage/seckillAuditGoodsList'),
  //       name: 'seckillAuditGoodsList',
  //       hidden: true,
  //       meta: {
  //         title: 'seckillAuditGoodsList',
  //         noCache: true
  //       }
  //     },
  //     {
  //       path: 'seckill-list/pass/:id',
  //       component: () => import('@/views/promotions/seckill-manage/seckillPassGoodsList'),
  //       name: 'seckillPassGoodsList',
  //       hidden: true,
  //       meta: {
  //         title: 'seckillPassGoodsList',
  //         noCache: true
  //       }
  //     }
  //   ]
  // },

  // 设置
  {
    path: '/setting',
    component: Layout,
    redirect: '/setting/system',
    name: 'setting',
    meta: {
      title: 'setting',
      icon: 'settings'
    },
    children: [

      {
        path: '/news/platinform',
        component: () => import('@/views/news/platinform'),
        name: 'platinform',
        meta: {
          title: 'platinform'
        }
      },
      // 见禅道5862
      // {
      //   path: '/news/protocol',
      //   component: () => import('@/views/news/protocol'),
      //   name: 'protocol',
      //   meta: {
      //     title: 'protocol'
      //   }
      // },
      {
        path: '/news/logistics',
        component: () => import('@/views/news/logistics'),
        name: 'logistics',
        meta: {
          title: 'logistics'
        }
      },
      // 本次更新不更新运费设置以及智能运营功能，需要更新时打开注释（运费设置）
      {
        path: '/transportg',
        component: () => import('@/views/setting/shop-settings/transportg'),
        name: 'transportg',
        meta: {
          title: 'transportg'
        }
      },
      {
        path: '/news/safemanege',
        component: () => import('@/views/news/safemanege'),
        name: 'safemanege',
        meta: {
          title: 'safemanege'
        }
      },
      // 系统设置
      {
        path: 'orderSystem',
        component: () => import('@/views/setting/shop-settings/systemSettingsOrder'),
        name: 'orderSystem',
        meta: {
          title: 'orderSystem'
        }
      },

      // { // SMTP设置
      //   path: 'smtp',
      //   component: () => import('@/views/setting/shop-settings/SMTPSettings'),
      //   name: 'smtpSettings',
      //   meta: {
      //     title: 'smtpSettings'
      //   }
      // },
      // { // 短信网关设置
      //   path: 'sms-gateway',
      //   component: () => import('@/views/setting/shop-settings/SMSGatewaySettings'),
      //   name: 'smsGatewaySettings',
      //   meta: {
      //     title: 'SMSGatewaySettings'
      //   }
      // },
      // { // 快递平台设置
      //   path: 'express-platform',
      //   component: () => import('@/views/setting/shop-settings/expressPlatformSettings'),
      //   name: 'expressPlatformSettings',
      //   meta: {
      //     title: 'expressPlatformSettings'
      //   }
      // },
      // { // 电子面单
      //   path: 'electronic-receipt',
      //   component: () => import('@/views/setting/shop-settings/electronicReceipt'),
      //   name: 'electronicrEceipt',
      //   meta: {
      //     title: 'electronicReceipt'
      //   }
      // },
      // 储存方案
      // {
      //   path: 'storage-solution',
      //   component: () => import('@/views/setting/shop-settings/storageSolution'),
      //   name: 'storageSolution',
      //   meta: {
      //     title: 'storageSolution'
      //   }
      // },
      // { // 静态页
      //   path: 'static-page',
      //   component: () => import('@/views/setting/shop-settings/staticPage'),
      //   name: 'staticPage',
      //   meta: {
      //     title: 'staticPage'
      //   }
      // },
      // { // 商品索引
      //   path: 'goods-index',
      //   component: () => import('@/views/setting/shop-settings/goodsIndex'),
      //   name: 'goodsIndex',
      //   meta: {
      //     title: 'goodsIndex'
      //   }
      // },
      // { // 店铺商品索引
      //   path: 'shop-goods-index',
      //   component: () => import('@/views/setting/shop-settings/shopGoodsIndex'),
      //   name: 'shopGoodsIndex',
      //   meta: {
      //     title: 'shopGoodsIndex'
      //   }
      // },
      // 信任登录
      // {
      //   path: 'trust-login',
      //   component: () => import('@/views/setting/shop-settings/trustLogin'),
      //   name: 'trustLogin',
      //   meta: {
      //     title: 'trustLogin'
      //   }
      // },
      // { // 店铺设置
      //   path: 'shop',
      //   component: () => import('@/views/setting/message-settings/shopMessage'),
      //   name: 'shopMessageSettings',
      //   meta: {
      //     title: 'shopMessage'
      //   }
      // },
      // { // 会员消息设置
      //   path: 'member',
      //   component: () => import('@/views/setting/message-settings/memberMessage'),
      //   name: 'memberMessageSettings',
      //   meta: {
      //     title: 'memberMessage'
      //   }
      // },
      // { // 商品推送
      //   path: 'goods',
      //   component: () => import('@/views/setting/push-settings/goodsPush'),
      //   name: 'goodsPush',
      //   meta: {
      //     title: 'goodsPush'
      //   }
      // },
      // { // APP推送
      //   path: 'app',
      //   component: () => import('@/views/setting/push-settings/appPush'),
      //   name: 'appPush',
      //   meta: {
      //     title: 'appPush'
      //   }
      // },
      // { // 支付设置
      //   path: 'payment',
      //   component: () => import('@/views/setting/payment-and-delivery/payment'),
      //   name: 'paymentSettings',
      //   meta: {
      //     title: 'payment'
      //   }
      // },
      // {
      //   path: 'express',
      //   component: () => import('@/views/setting/payment-and-delivery/express'),
      //   name: 'expressSettings',
      //   hidden: true,
      //   meta: {
      //     title: 'express'
      //   }
      // },
      // {
      //   path: 'regional-management',
      //   component: () => import('@/views/setting/payment-and-delivery/regionalManagement'),
      //   hidden: true,
      //   name: 'regionalManagementSettings',
      //   meta: {
      //     title: 'regionalManagement'
      //   }
      // },
      {
        path: 'imageList',
        component: () => import('@/views/setting/image-setting/imageList'),
        name: 'imageList',
        meta: {
          title: 'imageList'
        }
      },
      {
        path: 'imageTypeList',
        component: () => import('@/views/setting/image-setting/imageTypeList'),
        name: 'imageTypeList',
        meta: {
          title: 'imageTypeList'
        }
      },
      {
        path: 'giftCategoryList', // 礼包分类管理
        component: () => import('@/views/setting/gift-category/giftCategoryList'),
        name: 'giftCategoryList',
        meta: {
          title: 'giftCategoryList'
        }
      },
      {
        path: 'add',
        component: () => import('@/views/development/article-manage/addArticle'),
        name: 'addArticle1',
        hidden: true,
        meta: {
          title: 'addArticle1'
        }
      },
      {
        path: 'add',
        component: () => import('@/views/development/article-manage/addArticle'),
        name: 'addArticle',
        hidden: true,
        meta: {
          title: 'addArticle'
        }
      }
    ]
  },
  // 开发
  // {
  //   path: '/development',
  //   component: Layout,
  //   redirect: '/development/menu-manage',
  //   name: 'development',
  //   meta: {
  //     title: 'development',
  //     icon: 'development-manage'
  //   },
  //   children: [{
  //       path: 'menu-manage',
  //       component: () => import('@/views/development/tool-manage/menuManage'),
  //       name: 'menuManage',
  //       meta: {
  //         title: 'menuManage'
  //       }
  //     },
  //     // { path: 'article-category', component: () => import('@/views/development/article-manage/articleCategory'), name: 'articleCategory', meta: { title: 'articleCategory' }},
  //     // { path: 'article-list', component: () => import('@/views/development/article-manage/articleList'), name: 'articleList', meta: { title: 'articleList' }},
  //     {
  //       path: 'add',
  //       component: () => import('@/views/development/article-manage/addArticle'),
  //       name: 'addArticle',
  //       hidden: true,
  //       meta: {
  //         title: 'addArticle'
  //       }
  //     }

  //   ]
  // },
  // 权限
  {
    path: '/role',
    component: Layout,
    redirect: '/role/menu-manage',
    name: 'role',
    meta: {
      title: 'role',
      icon: 'jurisdiction'
    },
    children: [
      {
        path: 'administrator-manage',
        component: () => import('@/views/setting/auth-settings/administratorManage'),
        name: 'administratorManage',
        meta: {
          title: 'administratorManage'
        }
      },
      {
        path: 'role-manage',
        component: () => import('@/views/setting/auth-settings/roleManage'),
        name: 'roleManage',
        meta: {
          title: 'roleManage'
        }
      },
      {
        path: 'role-permission/:id(\\d+)',
        component: () => import('@/views/setting/auth-settings/rolePermission'),
        name: 'rolePermission',
        hidden: true,
        meta: {
          title: 'rolePermission',
          noCache: true,
          activeNames: ['roleManage']
        }
      }

    ]
  },

  {
    path: '*',
    redirect: '/404',
    hidden: true
  }
]

if (process.env.DISTRIBUTION) {
  // asyncRouters.push(disRouterMap)
}

export const asyncRouterMap = asyncRouters
