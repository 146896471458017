import router from './router'
import store from './store'
import NProgress from 'nprogress' // Progress 进度条
import 'nprogress/nprogress.css' // Progress 进度条样式
import { Message } from 'element-ui'
import Storage from '@/utils/storage'
import check404Type from "@/utils/check404Type";

const whiteList = ['/login']

router.beforeEach((to, from, next) => {
  NProgress.start()
  const refreshToken = Storage.getItem('admin_refresh_token')
  if (refreshToken) {
    if (to.path === '/login') {
      next({ path: '/' })
      NProgress.done()
    } else {
      if (store.getters.addRouters.length === 0) {
        store.dispatch('GenerateRoutes').then(() => {
          router.addRoutes(store.getters.addRouters)
          next({ ...to, replace: true })
        }).catch(() => {
          store.dispatch('fedLogoutAction').then(() => {
            Message.error('验证失败,请重新登录')
            window.location.replace(`/login?forward=${location.pathname}`)
          })
        })
      } else {
        // next()
        check404Type(to, from, next, NProgress)
      }
    }
  } else {
    if (whiteList.includes(to.path)) {
      next()
    } else {
      const login = '/login' + (location.pathname.length > 1 ? `?forward=${location.pathname}` : '');
      next({path: login});
      NProgress.done()
    }
  }
})

router.afterEach((info) => {
  const n = info.matched
  if (n && n.length && !n[0].meta.nullChild) {
    store.dispatch('toggleMenuOpen', true)
  } else {
    store.dispatch('toggleMenuOpen', false)
  }
  NProgress.done()
})
