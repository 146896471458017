<template>
  <el-scrollbar wrap-class="sidenav-scrollbar-wrapper" :view-style="sidenavWrapperStyle">
    <template v-for="(route, index) in permission_routers">
      <div v-if="!route.hidden" class="menu" :item="route" :base-path="route.path" :class="{ 'active': isActive(route) }">
        <el-tooltip placement="right" :key="route.name"
          :content="route.name ? generateTitle(route.name) : generateTitle(route.children[0].name)" :disabled="true"
          popper-class="app-menu-popper">
          <router-link :to="route.children.length > 0 ? { name: route.children[0].name } : { path: '/' }">
            <div class="menuButton" :style="menuButtonStyle">
              <template v-if="route.name">
                <div class="center">
                  <img v-if="route.meta && route.meta.icon"
                    :src="`/icons/${route.meta.icon}-${isActive(route) ? 'w' : 'b'}.png`" :style="menuButtonIconStyle"
                    alt="" />
                  <div class="title" :style="menuButtonTitleStyle">{{ generateTitle(route.name) }}</div>
                </div>
              </template>
              <template v-else>
                <div class="center">
                  <img v-if="route.children[0].meta && route.children[0].meta.icon"
                    :src="`/icons/${route.meta.icon}-${isActive(route) ? 'w' : 'b'}.png`" :style="menuButtonIconStyle"
                    alt="" />
                  <div class="title" :style="menuButtonTitleStyle">{{ generateTitle(route.children[0].name) }}</div>
                </div>
              </template>
            </div>
          </router-link>
        </el-tooltip>

        <div class="child-main-box" v-if="route.name" :style="childMainBoxStyle">
          <el-scrollbar wrap-class="item-menu-wrapper" v-if="permission_routers">
            <el-menu :default-active="$route.matched[1].name + ($route.matched[2] ? ('-' + $route.matched[2].name) : '')">
              <template v-for="(item, childIndex) in permission_routers[index].children">
                <template v-if="!item.hidden">
                  <el-submenu :index="item.name" v-if="item.children && item.children.length" :key="childIndex">
                    <template slot="title">
                      <div :style="subMenuTitleStyle">{{ generateTitle(item.name) }}</div>
                    </template>
                    <template v-for="(child, childIndex2) in item.children">
                      <router-link v-if="!child.hidden" :to="{ name: child.name }" :key="childIndex2" class="child3">
                        <el-menu-item :index="item.name + '-' + child.name" :style="subMenuItemStyle"
                          :class="{ activated: isSubItemActive(child) }">
                          <span>{{ generateTitle(child.meta.title) }}</span>
                        </el-menu-item>
                      </router-link>
                    </template>
                  </el-submenu>
                  <router-link :to="{ name: item.name }" v-else :key="childIndex">
                    <el-menu-item :index="item.name" :style="subMenuItemStyle"
                      :class="{ activated: isSubItemActive(item) }">
                      <span>{{ generateTitle(item.name) }}</span>
                    </el-menu-item>
                  </router-link>
                </template>
              </template>
            </el-menu>
          </el-scrollbar>
        </div>
      </div>
    </template>
  </el-scrollbar>
</template>

<script>
import { mapGetters } from 'vuex';
import SidebarItem from './SidebarItem';
import SvgIcon from '@/components/SvgIcon/index';
import { generateTitle } from '@/utils/i18n';
import Resize from '../../mixin/resize';

const $sub_menu_width = 235;

export default {
  components: {
    SidebarItem,
    SvgIcon,
  },
  mixins: [Resize],
  computed: {
    ...mapGetters(['permission_routers', 'sidebar', 'user', 'shopInfo']),
    isCollapse () {
      return !this.sidebar.opened;
    },
    sidenavWrapperStyle () {
      return {
        width: `${133 * this.scale}px`,
        marginRight: `${$sub_menu_width * this.scale}px`,
      };
    },
    menuButtonStyle () {
      return {
        margin: `${8 * this.scale}px auto 0`,
        borderRadius: `${10 * this.scale}px`,
        width: `${88 * this.scale}px`,
        height: `${88 * this.scale}px`,
        boxShadow: `0 ${10 * this.scale}px ${20 * this.scale}px rgba(26, 67, 169, 0.1)`,
      }
    },
    menuButtonIconStyle () {
      return {
        width: `${34 * this.scale}px`,
        height: `${34 * this.scale}px`,
        marginBottom: '4px',
      }
    },
    menuButtonTitleStyle () {
      return {
        fontSize: (18 * this.scale) > 12 ? `${18 * this.scale}px` : "12px",
      }
    },
    childMainBoxStyle () {
      return {
        width: `${$sub_menu_width * this.scale}px`,
        height: `${document.body.clientHeight - 80 * this.scale}px`,
      }
    },
    subMenuItemStyle () {
      return {
        padding: `${24 * this.scale}px ${20 * this.scale}px`,
        // fontSize: `${13 * this.scale}px`,
        fontsize: (13 * this.scale) > 12 ? `${13 * this.scale}px` : "12px",
      };
    },
    subMenuTitleStyle () {
      return {
        padding: `${24 * this.scale}px ${20 * this.scale}px ${24 * this.scale}px ${8 * this.scale}px`,
        // fontSize: `${13 * this.scale}px`,
        fontsize: (16 * this.scale) > 12 ? `${16 * this.scale}px` : "12px",
      };
    }
  },
  data () {
    return {
      activeIndex: 0
    };
  },
  methods: {
    generateTitle,
    linkIndex () {
      this.$router.push({ path: '/dashboard' });
    },
    hover (index) {
      if (index) {
        this.activeIndex = index;
      } else {
        this.activeIndex = 0;
      }
    },
    isActive (route) {
      return this.$route.matched[0].name === route.name;
    },
    isSubItemActive (item) {
      if (this.$route.meta.activeNames?.includes(item.name)) {
        return true;
      }
      if (item.path.includes('floor')) {
        return location.href.includes('floor');
      }
      return false;
    }
  }
};
</script>

<style lang="scss">
@import "src/plugins/_variables.scss";

$sub_menu_width: 235px;

.app-menu-popper {
  pointer-events: none;
}

.sidenav-scrollbar-wrapper {
  //width: 300px;
  overflow-x: hidden !important;
  // background-color: lighten(#001529, 1%);
  //background-color: #1E222D;
  background: #fff;
  //padding-right: $sub_menu_width !important;
  //padding-bottom: 100px;

  .el-scrollbar__view {
    //width: 300px - $sub_menu_width;
    //margin-right: $sub_menu_width;
    padding-bottom: 40px;
  }

  * {
    text-decoration: none !important;
    user-select: none;
  }

  .menu {
    //width: 64px;
    padding: 4px 0;

    &:first-child {
      //margin-top: 16px;
    }

    .menuButton {
      text-align: center;
      //color: #bebec0;
      color: #333;
      margin: 8px auto 0;
      //border-radius: 4px;
      border-radius: 10px;
      width: 88px;
      height: 88px;
      position: relative;
      overflow: hidden;
      box-shadow: 0 10px 20px rgba(26, 67, 169, 0.1);


      .center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        text-align: center;
      }

      .svg-icon {
        pointer-events: none;
        //width: 16px;
        //height: 16px;
        width: 34px;
        height: 34px;
        //transform: translateY(-2px);
        transition: all 375ms;
      }

      .title {
        margin-top: 4px;
        line-height: 1;
        //font-size: 13px;
        font-size: 17px;
        font-weight: bold;
        transition: all 175ms;
      }
    }

    &.active .menuButton,
    .menuButton:hover {
      font-weight: bold;

      .svg-icon {
        //transform: translateY(-3px) scale(1.2);
        transform: scale(1.1);
      }
    }

    &.active .menuButton {
      color: #fff;
      background-color: $--color-primary;
    }

    .menuButton:hover {
      //background-color: hsla(0, 0%, 0%, .1);
    }

    .child-main-box {
      position: absolute;
      right: 0;
      top: 0;
      width: $sub_menu_width;
      height: calc(100vh - 60px);
      opacity: 0;
      background: #fff;
      pointer-events: none;
      border-left: 4px solid #B8C2CC;

      .el-scrollbar,
      .el-scrollbar__view {
        height: 100%;
      }

      .item-menu-wrapper {
        overflow-x: hidden !important;
        height: 100%;
        //padding-top: 18px;

        .el-menu-item:hover,
        .el-menu-item:focus {
          background: transparent !important;
        }

        .el-menu-item {
          height: auto;
          line-height: 1;
          //padding: 12px 20px;
          padding: 24px 20px;
          font-size: 12.3px;
          min-width: 0;

          &:before {
            content: " ";
            position: absolute;
            left: 0;
            top: 50%;
            bottom: 0;
            width: 0;
            height: 100%;
            background-color: $--color-primary;
            opacity: 0;
            transform: translateY(-50%);
            transition: all 275ms;
            //border-radius: 0 40px 40px 0;
          }

          &.is-active:before,
          &.activated:before {
            opacity: 1;
            //width: calc(100% - 8px);
            //border-radius: 0 4px 4px 0;
            width: 100%;
          }

          &.is-active,
          &.activated {
            color: #fff;
          }

          span {
            position: relative;
          }
        }

        .el-submenu__title {
          height: auto;
          line-height: 1;
          padding: 0 !important;
          font-size: 13px;
        }
      }

      * {
        border: none;
      }
    }

    &:hover,
    &.active {
      .child-main-box {
        opacity: 1;
        pointer-events: visible;
      }
    }

    &:hover .child-main-box {
      z-index: 80;
    }
  }
}
</style>

